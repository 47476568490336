import React, {useEffect, useState} from 'react'
import moment from 'moment-business-days'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight, faCircleCheck, faTruck, faHouseChimney} from '@fortawesome/free-solid-svg-icons'
import Holidays from 'date-holidays'
import {GET_DAYS} from '../apollo/queries'
import {useLazyQuery} from '@apollo/client'
import 'moment-timezone'
import useDeliveryStatus from '../hooks/useDeliveryStatus'

const OrderHeader = props => {
  const {order} = props

  const [minimumShipDate, setMinimumShipDate] = useState(null);
  const [maximumShipDate, setMaximumShipDate] = useState(null);

  const [minimumDeliveryDate, setMinimumDeliveryDate] = useState(null);
  const [maximumDeliveryDate, setMaximumDeliveryDate] = useState(null);

  // const {status} = useDeliveryStatus(order?.source_id)

  useEffect(() => {
    getDays({
      variables: {},
    })

    console.log('order', order)
  }, [])

  const [getDays, {}] = useLazyQuery(GET_DAYS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const days = data.getDays.days

      console.log('days query results', days)

      const placed_date =
        new Date(order?.date_added).getTime() -
        new Date().getTimezoneOffset() * 60000

      let isApplied = true

      days.map(day => {
        let from_date = new Date(parseInt(day.from)).getTime()
        let to_date =
          day.to == 'Current'
            ? new Date().getTime()
            : new Date(parseInt(day.to)).getTime()

        if (from_date <= placed_date && to_date > placed_date) {
          isApplied = false

          const maximumShipDays = day.maximumShipDate
          const minimumShipDays = day.minimumShipDate

          const minimumDeliveryDays = day.minimumDeliveryDate
          const maximumDeliveryDays = day.maximumDeliveryDate

          const newMaximumShipDate = moment(
            convertDate(order?.date_added),
            'YYYY-MM-DD',
          )
            .businessAdd(maximumShipDays)
            .toDate()

          const newMinimumShipDate = moment(
            convertDate(order?.date_added),
            'YYYY-MM-DD',
          )
            .businessAdd(minimumShipDays)
            .toDate()

          const newMaximumDeliveryDate = moment(
            convertDate(order?.date_added)
          )
            .businessAdd(maximumDeliveryDays)
            .toDate()

          const newMinimumDeliveryDate = moment(
            convertDate(order?.date_added)
          )
            .businessAdd(minimumDeliveryDays)
            .toDate()

          setMinimumDeliveryDate(newMinimumDeliveryDate)
          setMaximumDeliveryDate(newMaximumDeliveryDate)

          setMinimumShipDate(newMinimumShipDate)
          setMaximumShipDate(newMaximumShipDate)
        }
      })

      if (isApplied) {
        const tempDate = moment(
          convertDate(order?.date_added),
          'YYYY-MM-DD',
        )
          .businessAdd(12)
          .toDate()

        setMinimumShipDate(tempDate)
      }
    },
    onError(error) {
      console.log('Done Error? ', error)
    },
  })

  const orderDate = order?.date_added

  const convertDate = value => {
    var momentObj = moment.utc(value, 'YYYY-MM-DD HH:mm:ss')

    var formattedDate = momentObj.local().format('YYYY/MM/DD')

    return formattedDate
    // const dateConvo = new Date(
    //   value.toString().replace(/ /g, 'T'),
    // )
    // dateConvo.setHours(dateConvo.getHours() - 4)

    // // const dateConvo = new Date(value)

    // // 12 / 7 / 2022; - Date Format
    // let d: string | number = dateConvo.getDate()
    // let m: string | number = dateConvo.getMonth() + 1
    // let y = dateConvo.getFullYear()

    // if (m.toString().length === 1) {
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    //   m = '0' + m.toString()
    // }
    // if (d.toString().length === 1) {
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    //   d = '0' + d.toString()
    // }

    // // setting up date format - mm/dd/yy 2022-7-28
    // const finalDate = y.toString() + '/' + m + '/' + d
    // // console.log(finalDate.replace(/ /g, 'T'),"finalDate")
    // return finalDate
  }

  //Ship date
  const hd = new Holidays('US')

  const currentYear = new Date().getFullYear()
  const publicHds1 = hd
    .getHolidays(currentYear)
    .filter(holdiay => holdiay.type == 'public')
  const publicHds2 = hd
    .getHolidays(currentYear + 1)
    .filter(holdiay => holdiay.type == 'public')
  const publicHds3 = hd
    .getHolidays(currentYear + 2)
    .filter(holdiay => holdiay.type == 'public')
  const holidays = [
    ...publicHds1,
    ...publicHds2,
    ...publicHds3,
  ].map(hd => {
    return hd.date.split(' ')[0]
  })

  // find the holiday for "2024-06-19" and remove it
  const index = holidays.indexOf('2024-06-19')
  if (index > -1) {
    holidays.splice(index, 1)
  }

  moment.updateLocale('us', {
    holidays: holidays,
    holidayFormat: 'YYYY-MM-DD',
    workingWeekdays: [1, 2, 3, 4, 5],
  })

  const shippingDate = moment(
    convertDate(orderDate),
    'YYYY-MM-DD',
    // eslint-disable-next-line react-hooks/exhaustive-deps
  )
    .businessAdd(12)
    .toDate()

  console.log('shippingDate', shippingDate)

  const deliveryDate = moment(
    convertDate(orderDate),
    'YYYY-MM-DD',
    // eslint-disable-next-line react-hooks/exhaustive-deps
  )
    .businessAdd(20)
    .toDate()

  const orderfromlocal = JSON.parse(
    localStorage.getItem('dangle-it-crm:lookup-state'),
  )

  const order_shipments = orderfromlocal?.order?.order_shipments
  const date_shipped = order_shipments?.find(
    v => v.date_added, // .date_shipped replaced with this
  )?.date_added // .date_shipped replaced with this

  const orderdates = convertDate(orderDate)


  const dates = convertDate(shippingDate)

  const getShipDateString = date => {
    var momentObj = moment.utc(date, 'YYYY-MM-DD HH:mm:ss')

    var formattedDate = momentObj
      .local()
      .format('ddd, MMM DD, YYYY')

    return formattedDate
    // const originalDate = new Date(date)

    // // Define arrays for the day names and month names
    // const daysOfWeek = [
    //   'Sun',
    //   'Mon',
    //   'Tue',
    //   'Wed',
    //   'Thu',
    //   'Fri',
    //   'Sat',
    // ]
    // const months = [
    //   'Jan',
    //   'Feb',
    //   'Mar',
    //   'Apr',
    //   'May',
    //   'Jun',
    //   'Jul',
    //   'Aug',
    //   'Sep',
    //   'Oct',
    //   'Nov',
    //   'Dec',
    // ]

    // // Get the day of the week, month, day of the month, and year
    // const dayOfWeek = daysOfWeek[originalDate.getUTCDay()]
    // const month = months[originalDate.getUTCMonth()]
    // const dayOfMonth = originalDate.getUTCDate()
    // const year = originalDate.getUTCFullYear()

    // // Create the formatted string
    // return `${dayOfWeek}, ${month}, ${dayOfMonth}, ${year}`
  }

  return (
    <>
      <div className="order-header">
        <div className="container text-blue order-header-container">
          <div className="row align-item">
            <div className="col-lg-4">
              <h1 className="main-heading text-blue mob-hide">
                Order #{props.orderID}
              </h1>
            </div>
            <div className="col-lg-8">
              <div className="order-date-box">
                <div className="order-date">
                  <FontAwesomeIcon
                    className="order-header-icon"
                    icon={faCircleCheck}
                  />
                  <div className="order-date-inner">
                    <p className="text-blue order-subtitle">Order Date</p>
                    <h5 className="bold text-blue f-22">
                      {/* {console.log(
                      convertDate(orderDate),
                      'orderDate',
                    )} */}
                      {new Date(
                        convertDate(orderDate),
                      ).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                      {/* {new Date('2011-04-12'.replace(/-/g, '/'))} */}
                    </h5>
                  </div>
                </div>
                <div className="order-date-arrow first-order-date-arrow">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <div className="ship-date">
                  <div>
                    <FontAwesomeIcon
                      className="order-header-icon big-icon"
                      icon={faTruck}
                    />
                  </div>
                  <div className="ship-date-inner">
                    <p className="text-blue order-subtitle">
                      {/* {date_shipped
                      ? ' Ship Date'
                      : ' Estimated Ship Date'} */}
                      Est. Ship Date
                    </p>
                    <div className="ship-date-range">
                      <h5 className="bold text-blue f-22">
                        {new Date(minimumShipDate).toLocaleDateString(
                          'en-US',
                          {
                            month: 'short',
                            day: 'numeric',
                          },
                        )}
                      </h5>
                      <div className="seperator bold text-blue f-22">-</div>
                      <h5 className="bold text-blue f-22">
                        {new Date(maximumShipDate).toLocaleDateString(
                          'en-US',
                          {
                            month: 'short',
                            day: 'numeric',
                          },
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="order-date-arrow">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <div className="delivery-date">
                  <div>
                    <FontAwesomeIcon
                      className="order-header-icon"
                      icon={faHouseChimney}
                    />
                  </div>
                  <div className="delivery-date-inner">
                    <p className="text-blue order-subtitle">Est. Delivery Date</p>
                    <div className="ship-date-range">
                      <h5 className="bold text-blue f-22">
                        {new Date(minimumDeliveryDate).toLocaleDateString(
                          'en-US',
                          {
                            month: 'short',
                            day: 'numeric',
                          },
                        )}
                      </h5>
                      <div className="seperator bold text-blue f-22">-</div>
                      <h5 className="bold text-blue f-22">
                        {new Date(maximumDeliveryDate).toLocaleDateString(
                          'en-US',
                          {
                            month: 'short',
                            day: 'numeric',
                          },
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderHeader
